<template>
  <div class="text-text1 w-full min-w-full">
    <p class="mb-2 font-semibold">{{title}}</p>
    <div class="date_picker_box ">
      <div class=" md:grid gap-4 grid-cols-2">
        <div class="flex">
          <div class="mb-4 w-full">
            <TextField :isPointer="true" :placholderText="`Start Date`" :setReadOnly="true" :autoFocus="false" :inputext="sendObj.schedStartDate" :showDate="true" :lableText="'Start Date'" :inputId="'Invoicedkid'" @onClickInputAction="startDatePikcer = true" />
            <div v-if="startDatePikcer" class="popup_overlay">
              <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                <DateTimePickerCustom
                    v-model="sendObj.schedStartDate"
                    v-if="startDatePikcer"
                    :showDate="true"
                    :title="'Start Date'"
                    :showOnlyDate="true"
                    :showStartDate="true"
                    :projectSection="true"
                    @closeDateTimepickerDialog="startDatePikcer = false">
                </DateTimePickerCustom>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" md:grid gap-4 grid-cols-2">
        <div v-if="!isAnyTime" class="mb-4">
          <TimePicker :title="'Start Time'" v-model="startTime"/>
        </div>
        <div v-if="!isAnyTime" class="mb-4">
          <TimePicker :title="'End Time'" v-model="endTime"/>
          <p v-if="timeErr" class="text-error mb-2">End time should be greater than Start time</p>
        </div>
          <!-- <div class="w-full mb-4">
            <div v-if="isAnyTime" class="card bg-gray1 items-center rounded-xl p-4  w-full flex gap-2 text-text1  mb-4">
              <div class="flex justify-between gap-2 items-center">
                <p>Time:</p>
                <div class="font-semibold heading-4">
                  Anytime
                </div>
              </div>
            </div>
             <div class="flex justify-end">
              <p v-if="!isAnyTime" @click="isAnyTime = true" class="text-primary cursor-pointer">Clear Time</p>
              <p v-else @click="isAnyTime = !isAnyTime" class="text-primary cursor-pointer">Set Time</p>
            </div>
          </div> -->
      </div>
      <!-- <div  class="grid grid-cols-2 gap-4">
        <div class="mb-4">
            <CheckBoxInput
            :isTextregular="true"
            :checkValue="sendObj.isScheduleLater"
            :label="`Schedule later`"
            :size="'small'"
            @setIsActive="sendObj.isScheduleLater = !sendObj.isScheduleLater"  />
        </div>
        <div class="mb-4">
            <CheckBoxInput
            :isTextregular="true"
            :checkValue="sendObj.isAllDay"
            :label="`All day`"
            :size="'small'"
            @setIsActive="sendObj.isAllDay = !sendObj.isAllDay"  />
        </div>
      </div> -->
      <!-- <div class="grid grid-cols-2 gap-4">
          <div class="mb-2">
            <Dropdown 
            :inputext="selectedRepeat" 
            :inputId="'repeatinputdorpdwn'"
            :lableText="'Repeat'"
            :placholderText="`Repeat`"
            :autoFocus="false"
            :showArrow="true"
            :setReadOnly="true"
            :searchCallApi="false"
            :showPlus="false"
            :keyName="'title'"
            :listId="'repId'"
            :items="rpeatLIst"
            @selectNewForVal="selectNewRepeat"
            />
          </div>
        </div> -->
        <div class="grid grid-cols-2 gap-4">
          <div class="mb-2" v-if="selectedRepeat !== 'Do not Repeat'">
            <p>Repeat End On</p>
            <span class="flex items-center cursor-pointer" @click="changesCheckbox()">
              <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" v-model="sendObj.isEndDateNeed" >
              <span class="pl-2">Does not end</span>
            </span>
            <p v-if="selectedRepeat !== 'Do not Repeat' && sendObj.showEndErr" class="text-error mb-2">Select future date for repeat end</p>
          </div>
          <div class="mb-2 sm:mb-0" v-if="selectedRepeat !== 'Do not Repeat' && !sendObj.isEndDateNeed">
          <TextField :isPointer="true" :placholderText="`Repeat EndDate`" :setReadOnly="true" :autoFocus="false" :inputext="sendObj.repeatEndsOn" :showDate="true" :lableText="'Repeat EndDate'" :inputId="'Invoicedkid2'" @onClickInputAction="endDatePicker = true" />
          <div v-if="endDatePicker" class="popup_overlay">
            <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
              <DateTimePickerCustom
                  :minDate="sendObj.schedStartDate"
                  v-model="sendObj.repeatEndsOn"
                  v-if="endDatePicker"
                  :showDate="true"
                  :title="'Repeat EndDate'"
                  :showOnlyDate="true"
                  :showStartDate="true"
                  :projectSection="true"
                  @closeDateTimepickerDialog="endDatePicker = false;sendObj.showEndErr=false">
              </DateTimePickerCustom>
            </div>
          </div>
        </div>
        </div>
    </div>
    <div class=" grid grid-cols-2 gap-4">
      <div class="mb-4">
        <MultiSelect 
        :inputext="employeeSearchText" 
        :inputId="'empslectinpuid'"
        :lableText="'Assign To'"
        :placholderText="`Assign To`"
        :autoFocus="false"
        :showArrow="true"
        :setReadOnly="false"
        :searchCallApi="false"
        :showPlus="false"
        :keyName="'fullName'"
        :idKey="'userDetailId'"
        :sectionId="'employeelistsecid'"
        :listId="'employeelistidb'"
        :items="employeeList"
        :selectedValues="selectedEmployee"
        @selectNewForVal="selectEmployee"
        :fieldError="assigneeErr !== ''"
        @onClickInputAction="assigneeErr = ''"
        />
        <p class="text-error mb-2" v-if="assigneeErr !== ''">{{assigneeErr}}</p>
      </div>
      <div class="mb-4 sm:mb-0" v-if="selectedEmployee.length > 0">
        <Dropdown 
            :inputext="selectReminderVal" 
            :inputId="'ReminderValue'"
            :lableText="'Team Reminder'"
            :placholderText="`Select Reminder`"
            :autoFocus="false"
            :showArrow="true"
            :setReadOnly="true"
            :searchCallApi="false"
            :showPlus="false"
            :keyName="'title'"
            :listId="'remId'"
            :items="reminderList"
            @selectNewForVal="selectReminder"
            />
      </div>
    </div>
    <div v-if="openCustomSel" class="popup_overlay px-4">
      <div class="custom_dialog rounded-xl" style="width: 550px; overflow: visible; max-height: 85%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between p-3">
              <div class=" font-medium text-white heading-3">
                Repeat
              </div>
            </div>
            <div class="p-4 text-text2">
              <div class="mb-4 sm:mb-0" >
                <Dropdown 
                  :inputext="selectFreqVal" 
                  :inputId="'reapetVal'"
                  :lableText="'Frequency'"
                  :placholderText="`Frequency`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="true"
                  :searchCallApi="false"
                  :showPlus="false"
                  :keyName="'title'"
                  :listId="'freqId'"
                  :items="frequencyList"
                  @selectNewForVal="selectFrequncy"
                  />
                </div>
                <div class="py-2 heading-5" v-if="selectFreqVal === 'Daily'">
                  Every <input v-model.number="dateValue" @focus="$event.target.select()" class="minuteBox cust_text_field text-center w-16 border focus:border-primary rounded flext items-center justify-center px-1 py-1 " type="number" v-mask="'##'"> Day(s)
                </div>
                <div v-if="selectFreqVal === 'Weekly'">
                  <div class="py-2 pt-4 heading-5">
                    Every <input v-model.number="dateValue" @focus="$event.target.select()" class="minuteBox cust_text_field text-center w-16 border focus:border-primary rounded flext items-center justify-center px-1 py-1 " type="number" v-mask="'##'"> Week(s) on:
                  </div>
                  <div class="text-left items-center flex gap-2 py-2">
                    <button v-for="(min, index) in weekArr" :key="index"
                      :class="min.isSel ? 'task_btn' : ' task_btn2 '"
                      @click="setWeekly(min, index)" :outlined="min.isSel" small>{{min.val}}
                    </button>
                  </div>
                </div>
                <div v-if="selectFreqVal === 'Monthly'">
                  <div class="py-2 pt-4 heading-5">
                    Every <input v-model.number="dateValue" @focus="$event.target.select()" class="minuteBox cust_text_field text-center w-16 border focus:border-primary rounded flext items-center justify-center px-1 py-1 " type="number" v-mask="'##'"> month(s):
                  </div>
                  <div class="items-center grid grid-cols-12">
                    <div v-for="(data, index) in monthObj" :key="index" class="flex col md:col-span-6">
                      <div class="flex items-center my-2" :id="data.value">
                        <input type="radio" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" v-model="radioModel" name="radio1" :value="data.id" @change="changeRadioVal()">
                        <div class="pl-3">
                          <div class="flex text-text2 "><p>{{data.value}}</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex ">
                    <div class="text-left items-center py-2 calanderCss relative" v-if="radioModel === 0">
                      <button v-for="(min, index) in MonthArr" :key="index" class="rounded-none "
                        :class="`${min.isSel ? 'task_btn' : ' task_btn2 '}`"
                        :style="min.val === '-1' ? '    width: 128px !important;position: absolute;bottom: 8px;right: 0;' : ''"
                        @click="setMonthly(min, index)" :outlined="min.isSel" small>{{min.showVal}}
                      </button>
                    </div>
                  </div>
                  <div class="text-left items-center gap-2 py-2 " v-if="radioModel === 1">
                    <div v-for="(data, index) in tempWeeokMonthArr" :key="index">
                      <div class="flex items-center">
                        <div class="w-11">{{index + 1}}{{ index + 1 === 1 ? 'st' : index + 1 === 2 ? 'nd' : index + 1 === 3 ? 'rd' : 'th' }}:</div>
                        <div class="text-left items-center flex gap-2 pb-1">
                          <button v-for="(min, indexB) in data.obj" :key="indexB"
                            :class="min.isSel ? 'task_btn' : ' task_btn2 '"
                            @click="setMonthlyWeek(min, indexB, data, index)" :outlined="min.isSel" small>{{min.val}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-2 dividerDark"></div>
                <div class="flex items-center" v-if="selectFreqVal === 'Daily'">
                  <div class="heading-5 text-gray4">Summary:</div>
                  <div class="heading-5 font-semibold text-text2 pl-2" v-if="selectFreqVal === 'Daily' && dateValue === 1">Daily</div>
                  <div class="heading-5 font-semibold text-text2 pl-2" v-if="selectFreqVal === 'Daily' && dateValue !== 1">Every {{ dateValue }} days</div>
                </div>
                <div class="flex items-center" v-if="selectFreqVal === 'Weekly'">
                  <div class="heading-5 font-semibold text-text2" v-if="selectedWeek.length > 0">{{dateValue === 1 ? 'Weekly' : `Every ${dateValue} weeks`}} on 
                    <span v-for="(data, indexA) in selectedWeek" :key="indexA">{{ data.fullName }}<span v-if="indexA + 1 !== selectedWeek.length">, </span> </span>
                  </div>
                  <div class="heading-5 font-semibold text-text2 flex" v-if="selectedWeek.length === 0">
                    <div class="heading-5 text-gray4">Summary:</div>
                    <div class="heading-5 font-semibold text-text2 pl-2">{{dateValue === 1 ? 'Weekly' : `Every ${dateValue} weeks`}}</div>
                  </div>
                </div>
                <div class="flex items-center" v-if="selectFreqVal === 'Monthly' && radioModel === 0">
                  <div class="heading-5 font-semibold text-text2" v-if="selectedMonth.length > 0">{{dateValue === 1 ? 'Monthly' : `Every ${dateValue} Months`}} on 
                    <span v-for="(data, indexA) in selectedMonth" :key="indexA">{{ data.DisplayVal }}<span v-if="indexA + 1 !== selectedMonth.length">, </span> </span>
                  </div>
                  <div class="heading-5 font-semibold text-text2 flex" v-if="selectedMonth.length === 0">
                    <div class="heading-5 text-gray4">Summary:</div>
                    <div class="heading-5 font-semibold text-text2 pl-2">{{dateValue === 1 ? 'Monthly' : `Every ${dateValue} Months`}}</div>
                  </div>
                </div>
                <div class="flex items-center" v-if="selectFreqVal === 'Monthly' && radioModel === 1">
                  <div class="heading-5 font-semibold text-text2" v-if="selectedMonth.length > 0">{{dateValue === 1 ? 'Monthly' : `Every ${dateValue} Months`}} on 
                    <span v-for="(data, indexA) in selectedMonth" :key="indexA">{{data.parentIndex}}{{ data.parentIndex === 1 ? 'st' : data.parentIndex === 2 ? 'nd' : data.parentIndex === 3 ? 'rd' : 'th' }} {{ data.childValue }}<span v-if="indexA + 1 !== selectedMonth.length">, </span> </span>
                  </div>
                  <div class="heading-5 font-semibold text-text2 flex" v-if="selectedMonth.length === 0">
                    <div class="heading-5 text-gray4">Summary:</div>
                    <div class="heading-5 font-semibold text-text2 pl-2">{{dateValue === 1 ? 'Monthly' : `Every ${dateValue} Months`}}</div>
                  </div>
                </div>
                <div class="flex justify-end">
                <div class="text-right flex">
                  <Button class="mr-2" :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="closePopup(false)"/>
                  <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="closePopup(true)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import Dropdown from '@/View/components/dropdown.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import TimePicker from '@/View/job/components/timePicker.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
import moment from 'moment'
export default {
  name: 'ScheduleVisit',
  components: {
    MultiSelect,
    Dropdown,
    Button,
    TimePicker,
    TextField,
    DateTimePickerCustom,
  },
  data () {
    return {
      monthObj: [
        {id: 0, value: 'Day of Month', isSel: false},
        {id: 1, value: 'Day of Week', isSel: false},
      ],
      assigneeErr: '',
      openCustomSel: false,
      startDatePikcer: false,
      endDatePicker: false,
      selectedMonth: [],
      weekArr: [
        {id: 0, val: 'S', fullName: 'Sunday', isSel: false},
        {id: 1, val: 'M', fullName: 'Monday', isSel: false},
        {id: 2, val: 'T', fullName: 'Tuesday', isSel: false},
        {id: 3, val: 'W', fullName: 'Wednesday', isSel: false},
        {id: 4, val: 'T', fullName: 'Thursday', isSel: false},
        {id: 5, val: 'F', fullName: 'Friday', isSel: false},
        {id: 6, val: 'S', fullName: 'Saturday', isSel: false}
      ],
      MonthArr: [
        {id: 1, showVal: 1,  val: '1st', DisplayVal: '1st', isSel: false},
        {id: 2, showVal: 2,  val: '2nd', DisplayVal: '2nd', isSel: false},
        {id: 3, showVal: 3,  val: '3rd', DisplayVal: '3rd', isSel: false},
        {id: 4, showVal: 4,  val: '4th', DisplayVal: '4th', isSel: false},
        {id: 5, showVal: 5,  val: '5th', DisplayVal: '5th', isSel: false},
        {id: 6, showVal: 6,  val: '6th', DisplayVal: '6th', isSel: false},
        {id: 7, showVal: 7,  val: '7th', DisplayVal: '7th', isSel: false},
        {id: 8, showVal: 8,  val: '8th', DisplayVal: '8th', isSel: false},
        {id: 9, showVal: 9,  val: '9th', DisplayVal: '9th', isSel: false},
        {id: 10, showVal: 10, val: '10th', DisplayVal: '10th', isSel: false},
        {id: 11, showVal: 11, val: '11th', DisplayVal: '11th', isSel: false},
        {id: 12, showVal: 12, val: '12th', DisplayVal: '12th', isSel: false},
        {id: 13, showVal: 13, val: '13th', DisplayVal: '13th', isSel: false},
        {id: 14, showVal: 14, val: '14th', DisplayVal: '14th', isSel: false},
        {id: 15, showVal: 15, val: '15th', DisplayVal: '15th', isSel: false},
        {id: 16, showVal: 16, val: '16th', DisplayVal: '16th', isSel: false},
        {id: 17, showVal: 17, val: '17th', DisplayVal: '17th', isSel: false},
        {id: 18, showVal: 18, val: '18th', DisplayVal: '18th', isSel: false},
        {id: 19, showVal: 19, val: '19th', DisplayVal: '19th', isSel: false},
        {id: 20, showVal: 20, val: '20th', DisplayVal: '20th', isSel: false},
        {id: 21, showVal: 21, val: '21th', DisplayVal: '21th', isSel: false},
        {id: 22, showVal: 22, val: '22nd', DisplayVal: '22nd', isSel: false},
        {id: 23, showVal: 23, val: '23th', DisplayVal: '23th', isSel: false},
        {id: 24, showVal: 24, val: '24th', DisplayVal: '24th', isSel: false},
        {id: 25, showVal: 25, val: '25th', DisplayVal: '25th', isSel: false},
        {id: 26, showVal: 26, val: '26th', DisplayVal: '26th', isSel: false},
        {id: 27, showVal: 27, val: '27th', DisplayVal: '27th', isSel: false},
        {id: 28, showVal: 28, val: '28th', DisplayVal: '28th', isSel: false},
        {id: 29, showVal: 29, val: '29th', DisplayVal: '29th', isSel: false},
        {id: 30, showVal: 30, val: '30th', DisplayVal: '30th', isSel: false},
        {id: 31, showVal: 31, val: '31th', DisplayVal: '31th', isSel: false},
        {id: -1, showVal: 'Last Day', val: '-1',  DisplayVal: 'Last Day', isSel: false},
      ],
      tempWeeokMonthArr: [],
      sendObj: {
        isEndDateNeed: false,
        showEndErr: false,
        repeatEndsOn: '',
        isScheduleLater: false,
        isAllDay: false,
        isRepeat: false,
        schedStartDate: '',
        schedEndDate: '',
      },
        isUnSchJob: false,
        typeForVi: '',
        employeeSearchText: '',
        selectedEmployee: [],
        employeeList: [],
        frequencyList: [
          {freqId: 0, title: 'Daily'},
          {freqId: 2, title: 'Weekly'},
          {freqId: 3, title: 'Monthly'},
        ],
        selectedWeek: [],
        dateValue: 1,
        radioModel: 0,
        rpeatLIst: [
          {repId: 0, title: 'Do not Repeat', value: 'Never'},
          {repId: 1, title: 'Everyday', value: 'everyday'},
          {repId: 2, title: 'Every week on Wednesday', value: 'week'},
          {repId: 3, title: 'Every month on 18', value: 'month'},
          {repId: 4, title: 'Custom', value: 'custom'},
        ],
        reminderList: [
          {remId: 0, title: 'No reminder set', value: -1},
          {remId: 1, title: 'At start of task', value: 0},
          {remId: 2, title: '5 min prior', value: 5},
          {remId: 3, title: '10 min prior', value: 10},
          {remId: 4, title: '15 min prior', value: 15},
          {remId: 5, title: '30 min prior', value: 30},
          {remId: 6, title: '1 hour prior', value: 60},
          {remId: 7, title: '2 hours prior', value: 120},
          {remId: 8, title: '5 hours prior', value: 300},
          {remId: 9, title: '12 hours prior', value: 720},
          {remId: 10, title: '24 hours prior', value: 1440}
        ],
        numberOfVisit: 0,
        repeatViType: '',
        selectFreqVal: 'Daily',
        selectReminderVal: 'No reminder set',
        selectedRepeat: 'Do not Repeat',
        timeErr: false,
        isAnyTime: false,
        startTime: "11:30",
        endTime: "11:30",
        firstVisit: "",
        lastVisit: "",
        isMultiVisit: true,
        selectedVisits: [],
        weekDayList: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    }
  },
  props: ['isFromSchedule', 'sTime', 'eTime', 'isSelect', 'type', 'title', 'showEmployeeSelection', 'showJobItemSelection', 'jobDetail'],
   created() {
  },
  beforeMount () {
    if (this.$route.query.startTime === undefined) {
      this.setHourTime()
    } else {
      console.log("route12313", this.$route.query.startTime);
      let startD = JSON.parse(this.$route.query.startTime)
      var d1 = new Date(startD);
      var minute = d1.getMinutes();
      var hour = d1.getHours();
      this.startTime = hour + ':' + minute
      this.sendObj.schedStartDate = moment(new Date(startD)).format('YYYY-MM-DD')
      
      let endD = JSON.parse(this.$route.query.endTime)
      var d2 = new Date(endD);
      var minute2 = d2.getMinutes();
      var hour2 = d2.getHours();
      this.endTime = hour2 + ':' + minute2
      this.sendObj.schedEndDate = moment(new Date(endD)).format('YYYY-MM-DD')
      this.setFullTIme()
    }
  },
  mounted () {
    this.rpeatLIst.forEach(element => {
      if(element.repId === 3) {
        const dayOfMonth = new Date().getDate();
        element.title = 'Every month on ' + dayOfMonth
      }
      if(element.repId === 2) {
        const dayOfMonth = this.weekDayList[new Date().getDay()]
        element.title = 'Every week on ' + dayOfMonth
      }
    });
    if (this.$route.query.startTime === undefined) {
      this.setHourTime()
      this.sendObj.schedStartDate = moment(new Date()).format('YYYY-MM-DD')
      this.sendObj.schedEndDate = moment(new Date()).format('YYYY-MM-DD')
      // this.visitStartDateTime = moment(new Date()).format('YYYY-MM-DD')
      // this.visitEndDateTime = moment(new Date()).format('YYYY-MM-DD')
    } else {
      console.log("route12313", this.$route.query);
      let startD = JSON.parse(this.$route.query.startTime)
      var d1 = new Date(startD);
      var minute = d1.getMinutes();
      var hour = d1.getHours();
      this.startTime = hour + ':' + minute
      this.sendObj.schedStartDate = moment(new Date(startD)).format('YYYY-MM-DD')
      
      let endD = JSON.parse(this.$route.query.endTime)
      var d2 = new Date(endD);
      var minute2 = d2.getMinutes();
      var hour2 = d2.getHours();
      this.endTime = hour2 + ':' + minute2
      this.sendObj.schedEndDate = moment(new Date(endD)).format('YYYY-MM-DD')
      this.setFullTIme()
    }
    this.getEmployeeList()
    this.getTempWeekofMonthArray()
    console.log('his.tempWeeokMonthArr', this.tempWeeokMonthArr)
    this.$root.$on('assigneeErr', (response) => {
      if (response) {
        this.assigneeErr = 'Assignee is required'
      } else {
        this.assigneeErr = ''
      }
    })
  },
  beforeDestroy () {
  },
  watch: {
    isAnyTime: {
      handler () {
        if (this.isAnyTime) {
          this.startTime = '12:00'
          this.endTime = '23:59'
          this.setFullTIme()
        } else {
          this.setHourTime()
        }
      }
    },
    dateValue: {
      handler () {
        if (this.dateValue === 0 || this.dateValue === '0' || this.dateValue === '') {
          this.dateValue = 1
        }
      }, 
      deep: true
    },
    sendObj: {
      handler () {
        console.log('sendObj', this.sendObj)
        this.setFullTIme()
      }, 
      deep: true
    },
    startTime: {
      handler () {
        this.timeErr = false
           let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
        this.setFullTIme()
      },
      deep: true
    },
    endTime: {
      handler () {
        this.timeErr = false
        let x = this.startTime.split(':')
          let hour = parseInt(x[0])
          let min = parseInt(x[1])
         let y = this.endTime.split(':')
          let hr2 = parseInt(y[0])
          let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
        this.setFullTIme()
      },
      deep: true
    }
  },
  methods: {
    setFullTIme () {
      let temp1 = moment(this.sendObj.schedStartDate).format('YYYY-MM-DD')
      let strDate = temp1 + ' ' + this.startTime
      let endDate = temp1 + ' ' + this.endTime
      let Obj = {
        compStartTime: new Date(strDate).toUTCString(),
        compEndTime: new Date(endDate).toUTCString(),
        Error: this.timeErr,
        isScheduleLater: this.sendObj.isScheduleLater,
        isAllDay: this.sendObj.isAllDay,
        repeatEndsOn: this.sendObj.repeatEndsOn,
        isEndDateNeed: this.sendObj.isEndDateNeed,
        showEndErr: this.sendObj.showEndErr
      }
      setTimeout(() => {
        this.$root.$emit('scheduleTimeEmit', Obj)
      }, 200);
    },
    getTempWeekofMonthArray () {
      for (let index = 0; index < 4; index++) {
        let Obj = {
          parentId: index,
          obj: JSON.parse(JSON.stringify(this.weekArr))
        }
        this.tempWeeokMonthArr.push(Obj)
      }
    },
    closePopup (value) {
      if (!value) {
        this.resetAllValue()
        this.selectFreqVal = 'Daily'
      } else {
        console.log('selectFreqVal', this.selectFreqVal)
        if (this.selectFreqVal === 'Daily' && this.dateValue === 1) {
          this.selectedRepeat = 'Summary: Daily'
        } else if (this.selectFreqVal === 'Daily' && this.dateValue !== 1) {
          this.selectedRepeat = `Every ${this.dateValue} days`
        } else if (this.selectFreqVal === 'Weekly' && this.selectedWeek.length > 0) {
          let temp1 = this.dateValue === 1 ? 'Weekly on' : `Every ${this.dateValue} weeks on`
          let temp2 = ''
          for (let index = 0; index < this.selectedWeek.length; index++) {
            console.log('this.selectedWeek[index].fullName', this.selectedWeek[index].fullName)
            temp2 +=  this.selectedWeek[index].fullName + ', '
          }
          console.log('temp2', temp2)
          this.selectedRepeat = temp1 + ' ' + temp2
        } else if (this.selectFreqVal === 'Weekly' && this.selectedWeek.length === 0) {
          if (this.dateValue === 1) {
            this.selectedRepeat = 'Summary: Weekly'
          } else {
            this.selectedRepeat = `Every ${this.dateValue} Weeks`
          }
        } else if (this.selectFreqVal === 'Monthly' && this.radioModel === 0) {
          if (this.selectedMonth.length > 0) {
            let temp1 = this.dateValue === 1 ? 'Monthly on' : `Every ${this.dateValue} Months on`
            let temp2 = ''
            for (let index = 0; index < this.selectedMonth.length; index++) {
              console.log('this.selectedMonth[index].val', this.selectedMonth[index].DisplayVal)
              temp2 +=  this.selectedMonth[index].DisplayVal + ', '
            }
            this.selectedRepeat = temp1 + ' ' + temp2
          } else {
            if (this.dateValue === 1) {
              this.selectedRepeat = 'Summary: Monthly'
            } else {
              this.selectedRepeat = `Every ${this.dateValue} Months`
            }
          }
        } else if (this.selectFreqVal === 'Monthly' && this.radioModel === 1) {
          if (this.selectedMonth.length > 0) {
            let temp1 = this.dateValue === 1 ? 'Monthly on' : `Every ${this.dateValue} Months on`
            let temp2 = ''
            for (let index = 0; index < this.selectedMonth.length; index++) {
              console.log('this.selectedMonth[index].val', this.selectedMonth[index].childValue)
              temp2 +=   this.selectedMonth[index].parentIndex + 'nd ' + this.selectedMonth[index].childValue + ', '
            }
            this.selectedRepeat = temp1 + ' ' + temp2
          } else {
            if (this.dateValue === 1) {
              this.selectedRepeat = 'Summary: Monthly'
            } else {
              this.selectedRepeat = `Every ${this.dateValue} Months`
            }
          }
        } else {
          this.selectedRepeat = 'Custom'
        }
      }
      this.openCustomSel = false
      let object = {
        selectedRepeat: this.selectedRepeat,
        dateValue: this.dateValue,
        isRepeat: this.sendObj.isRepeat,
        selectFreqVal: this.selectFreqVal,
        selectedWeekData: this.selectedWeek,
        selectedMonthData: this.selectedMonth,
        radioModel: this.radioModel
      }
      if (this.selectedRepeat === 'Do not Repeat') {
        object.isRepeat = false
      } else {
        object.isRepeat = true
      }
      this.$root.$emit('repeatScheduleValue', object)
    },
    setWeekly (value, indexA) {
      console.log('this.weekArr[indexA]', this.weekArr[indexA])
      if (!value.isSel) {
        this.selectedWeek.push(value)
        value.isSel = true
      } else {
        const findex = this.selectedWeek.findIndex(x => x.fullName === value.fullName)
        this.weekArr[indexA].isSel = false
        this.selectedWeek.splice(findex, 1)
      }
    },
    setMonthly (value, indexA) {
      console.log('this.weekArr[indexA]', this.MonthArr[indexA])
      if (!value.isSel) {
        this.selectedMonth.push(value)
        value.isSel = true
      } else {
        const findex = this.selectedMonth.findIndex(x => x.DisplayVal === value.DisplayVal)
        this.MonthArr[indexA].isSel = false
        this.selectedMonth.splice(findex, 1)
      }
    },
    // setMonthlyWeek (value, indexA) {
    setMonthlyWeek (childData, childIndex, parentData, parentIndex) {
      console.log('this.tempWeeokMonthArr', this.tempWeeokMonthArr)
      console.log('this.childData', childData)
      console.log('this.childIndex', childIndex)
      console.log('this.parentData', parentData)
      console.log('this.parentIndex', parentIndex)
      // for (let II = 0; II < this.tempWeeokMonthArr.length; II++) {
      //   for (let JJ = 0; JJ < this.tempWeeokMonthArr[II].obj.length; JJ++) {
      //     if (this.tempWeeokMonthArr[II].obj[JJ].fullName === childData.fullName && this.tempWeeokMonthArr[II].parentId === parentIndex) {
      //       this.tempWeeokMonthArr[II].obj[JJ].isSel = true
      //     }
      //   }
      // }
      let Obj = {
        parentIndex: parentData.parentId + 1,
        childValue: childData.fullName,
        childId: childData.id
      }
      if (!childData.isSel) {
        childData.isSel = true
        console.log('Obj', Obj)
        this.selectedMonth.push(Obj)
      } else {
        const findex = this.selectedMonth.findIndex(x => x.childValue === childData.fullName)
        childData.isSel = false
        this.selectedMonth.splice(findex, 1)
      }
      console.log('parentData', parentData, this.tempWeeokMonthArr)
      console.log('this.selectedMonth ----->', this.selectedMonth)
      this.$root.$emit('temporaryData', this.tempWeeokMonthArr)
    },
    selectEmployee (emplist) {
      this.selectedEmployee = []
      let tempEmpList = []
      emplist.map((data) => {
        let obj = {
          userDetailId: data.userDetailId,
          fullName: data.fullName
        }
        tempEmpList.push({userDetailId:data.userDetailId})
        this.selectedEmployee.push(obj)
      })
      setTimeout(() => {
         this.$root.$emit('setSelectedEmpHandler', tempEmpList)
      }, 200);
    },
    selectNewRepeat (data) {
      console.log('data22', data)
      this.selectedRepeat = data !== null ? data.title : 'Do not Repeat'
      if (data.repId === 4) {
        this.openCustomSel = true
      }
      this.sendObj.isRepeat = true
      if (data.repId === 0) {
        this.sendObj.repeatEndsOn = moment(new Date()).format('YYYY-MM-DD')
        this.sendObj.isRepeat = false
      }
      if (!this.sendObj.isEndDateNeed) {
        this.sendObj.showEndErr = true
      }
      this.sendObj.repeatEndsOn = ''
      this.$root.$emit('repeatSchData', this.sendObj, data.value)
      // this.$root.$emit('setSheduleDataEmpHandler',{repeatedType: this.repeatViType, repeatedDayOrDate: data.val})
    },
    changesCheckbox () {
      this.sendObj.repeatEndsOn = ''
      this.sendObj.isEndDateNeed = !this.sendObj.isEndDateNeed
      if (this.sendObj.isEndDateNeed) {
        this.sendObj.showEndErr = false
      } else {
        this.sendObj.showEndErr = true
      }
    },
    resetAllValue () {
      console.log('resetAllValue')
      this.changeRadioVal()
      this.dateValue = 1
      this.weekArr.forEach(element => {
        element.isSel = false
      });
      this.selectedWeek = []
    },
    changeRadioVal () {
      console.log('click ')
      this.MonthArr.forEach(element => {
        element.isSel = false
      });
      this.tempWeeokMonthArr = []
      this.getTempWeekofMonthArray()
      this.selectedMonth = []
    },
    selectFrequncy (data) {
      this.resetAllValue()
      console.log('data', data)
      this.selectFreqVal = data.title
    },
    selectReminder (data) {
      console.log('data', data)
      this.selectReminderVal = data !== null ? data.title : 'No reminder set'
      this.$root.$emit('setReminderVal', data)
      // this.$root.$emit('setSheduleDataEmpHandler',{repeatedType: this.repeatViType, repeatedDayOrDate: data.val})
    },
    setHourTime () {
      let hours = new Date().getHours()
      let min = new Date().getMinutes()
      hours = min > 45 &&  min < 60 ? hours + 1 : hours
      let minutes = min <= 15 ? 15 : min <= 30 ? 30 : min <= 45 ? 45 : min <= 60 ? 0 : 0
      
      this.startTime = hours + ':' + minutes
      this.endTime = hours + 1 + ':' + minutes
      console.log('123 call, 1', this.startTime)
      console.log('123 call, 1', this.endTime)
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map(el => {
               tempList.push({...el, fullName: el.firstName + ' ' + el.lastName})
            })
          }
          this.employeeList = tempList
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
}
</script>
<style scoped>
.calanderCss {
  display: grid;
  /* display: flex; */
  max-width: 240px;
  grid-template-columns: repeat(7, 1fr);
}
</style>