<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
                New Customer
            </div>
          </div>
          <div class="p-4">
            <div class="sm:grid grid-cols-12 gap-3">
              <div class="col-span-6 mb-4 sm:mb-0">
                <TextField
                  :inputId="'cusfirstnameid'"
                  :textMaxlength="50"
                  :showcharLimit="true"
                  :inputext="custDetail.firstName"
                  :fieldError="errObj.firstNameErr !== ''"
                  :placholderText="`First Name`"
                  :lableText="'First Name'"
                  :autoFocus="true"
                  @keyPressAction="errObj.firstNameErr = ''"
                  @inputChangeAction="(data) => custDetail.firstName = data"  />

                  <p class="text-error heading-7">{{errObj.firstNameErr}}</p>
               </div>
              <div class="col-span-6 mb-4 sm:mb-0">
                <TextField
                  :inputId="'cuslastnameid'"
                  :textMaxlength="50"
                  :showcharLimit="true"
                  :inputext="custDetail.lastName"
                  :fieldError="errObj.lastNameErr !== ''"
                  :placholderText="`Last Name`"
                  :lableText="'Last Name'"
                  :autoFocus="false"
                  @keyPressAction="errObj.lastNameErr = ''"
                  @inputChangeAction="(data) => custDetail.lastName = data"  />

                  <p class="text-error heading-7">{{errObj.lastNameErr}}</p>
               </div>
              <div class="col-span-6 mb-4 sm:mb-0">
                <NumberInput
                :showcharLimit="true"
                :inputId="'custprimnumber'"
                :inputext="custDetail.phoneNumber"
                :textMaxlength="12"
                :placholderText="`Primary Number`"
                :lableText="'Primary Number'"
                :inputType="'text'"
                :autoFocus="false"
                :fieldError="errObj.primNumErr !== '' || numberExist1"
                :setReadOnly="false"
                @keyPressAction="errObj.primNumErr = ''; numberExist1 = false"
                @blurAction="checkContactNumber('prime')"
                @inputChangeAction="(data) => custDetail.phoneNumber = data"  />

                  <p class="text-error heading-7">{{errObj.primNumErr}}</p>
                  <div v-if="numberExist1">
                    <p v-if="numberExist1" class="text-error">Number already in use, please use another</p>
                    <span v-for="(data, index) in existArr1" :key="index">
                      <li class="heading-6 text-primary cursor-pointer" @click="routingTodata(data)">{{ data.firstName }} {{ data.firstName }}({{ data.isLead ? 'Lead' : 'Customer'}})</li>
                    </span>
                  </div>
               </div>
              <div class="col-span-6 mb-4 sm:mb-0">
                <TextField
                    :inputId="'customeremail'"
                    :inputext="custDetail.emailAddress"
                    :placholderText="`Email Address`"
                    :showcharLimit="true"
                    :textMaxlength="250"
                    :lableText="'Email Address'"
                    :inputType="'text'"
                    @blurAction="checkEmail"
                    :autoFocus="false"
                    :setReadOnly="false"
                    @keyPressAction="errObj.emailErr = '';emailExist = false"
                    :fieldError="errObj.emailErr !== '' || emailExist"
                    @inputChangeAction="(data) => custDetail.emailAddress = data"  />

                  <p class="text-error heading-7">{{errObj.emailErr}}</p>
                  <div v-if="emailExist">
                    <p v-if="emailExist" class="text-error">Email already in use, please use another</p>
                    <span v-for="(data, index) in existArr3" :key="index">
                      <li class="heading-6 text-primary cursor-pointer" @click="routingTodata(data)">{{ data.firstName }} {{ data.firstName }}({{ data.isLead ? 'Lead' : 'Customer'}})</li>
                    </span>
                  </div>
               </div>
               <div class="col-span-12">
                <TextField
                  :inputId="'companyNameid'"
                  :textMaxlength="50"
                  :inputext="custDetail.companyName"
                  :placholderText="`Company Name`"
                  :showcharLimit="true"
                  :lableText="'Company Name'"
                  :autoFocus="false"
                  @inputChangeAction="(data) => custDetail.companyName = data"  />
               </div>
            </div>
            <div>
                <p class="text-text2 font-bold heading-5 py-3">Additional Details</p>

                <div class="sm:grid grid-cols-12 gap-3">
                  <div class=" col-span-6 mb-4">
                    <NumberInput
                    :inputId="'custothernumber'"
                    :inputext="custDetail.otherNumber"
                    :textMaxlength="12"
                    :placholderText="`Other Number`"
                    :lableText="'Other Number'"
                    :showcharLimit="true"
                    :inputType="'text'"
                    :autoFocus="false"
                    :fieldError="errObj.otherNumberErr !== '' || numberExist2"
                    :setReadOnly="false"
                    @keyPressAction="errObj.otherNumberErr = ''; numberExist2 = false"
                    @blurAction="checkContactNumber('other')"
                    @inputChangeAction="(data) => custDetail.otherNumber = data"  />

                  <p class="text-error heading-7">{{errObj.otherNumberErr}}</p> 
                  <div v-if="numberExist2">
                    <p v-if="numberExist2" class="text-error">Number already in use, please use another</p>
                    <span v-for="(data, index) in existArr2" :key="index">
                      <li class="heading-6 text-primary cursor-pointer" @click="routingTodata(data)">{{ data.firstName }} {{ data.firstName }}({{ data.isLead ? 'Lead' : 'Customer'}})</li>
                    </span>
                  </div>
                  </div>
                  <!-- <div class=" col-span-6 mb-4 ">
                    <div class="pr-4 label_css">
                        Rating
                    </div>
                    <div class="flex   items-center  text-2xl">
                      <star-rating :star-size="40" active-color="#107591" inactive-color="#1295ba0f" v-model="custDetail.rating" :show-rating="false"></star-rating> -->
                        <!-- <span @click="custDetail.rating = 1" :class="`${custDetail.rating >= 1 ? 'text-primary' : 'text-gray3' } p-1 text-3xl relative`">
                            <i  :class="`fa-solid fa-star hover:text-primary cursor-pointer`"></i>
                            <span class=" absolute heading-7 text-white cursor-pointer top-1.5 right-4 pr-0.5">1</span>
                        </span>
                        <span @click="custDetail.rating = 2" :class="`${custDetail.rating >= 2 ? 'text-primary' : 'text-gray3' } p-1 text-3xl relative`">
                            <i  :class="`fa-solid fa-star hover:text-primary cursor-pointer`"></i>
                            <span class=" absolute heading-7 text-white cursor-pointer top-1.5 right-4 pr-0.5">2</span>
                        </span>
                        <span @click="custDetail.rating = 3" :class="`${custDetail.rating >= 3 ? 'text-primary' : 'text-gray3' } p-1 text-3xl relative`">
                            <i  :class="`fa-solid fa-star hover:text-primary cursor-pointer`"></i>
                            <span class=" absolute heading-7 text-white cursor-pointer top-1.5 right-4 pr-0.5">3</span>
                        </span>
                        <span @click="custDetail.rating = 4" :class="`${custDetail.rating >= 4 ? 'text-primary' : 'text-gray3' } p-1 text-3xl relative`">
                            <i  :class="`fa-solid fa-star hover:text-primary cursor-pointer`"></i>
                            <span class=" absolute heading-7 text-white cursor-pointer top-1.5 right-4 pr-0.5">4</span>
                        </span>
                        <span @click="custDetail.rating = 5" :class="`${custDetail.rating >= 5 ? 'text-primary' : 'text-gray3' } p-1 text-3xl relative`">
                            <i  :class="`fa-solid fa-star hover:text-primary cursor-pointer`"></i>
                            <span class=" absolute heading-7 text-white cursor-pointer top-1.5 right-4 pr-0.5">5</span>
                        </span> -->
                    <!-- </div>
                  </div> -->
               
                  <div class=" col-span-12 mb-4">
                    <TextAreaInput
                      :inputId="'discriptioncon'"
                      :inputext="custDetail.hotNotes"
                      :placholderText="`Write notes here..`"
                      :lableText="'Hot Notes'"
                      :inputType="'text'"
                      :autoFocus="false"
                      :textMaxlength="1000"
                      :showcharLimit="true"
                      :cols="50"
                      :rows="3"
                      :setReadOnly="false"
                      @inputChangeAction="(data) => custDetail.hotNotes = data"  />
                  </div>
                </div>

            </div>
          </div>
        </div>
        <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
          <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveNewCustomer()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import StarRating from 'vue-star-rating'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import NumberInput from '@/View/components/number-text-field.vue'
import { validateEmail, checkSpeialChar } from "@/utils/validation.js";
import TextAreaInput from '@/View/components/textAreaInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    TextAreaInput,
    Button,
    NumberInput,
    TextField,
    // StarRating
  },
  data () {
    return {
      emailExist: false,
      numberExist2: false,
      numberExist1: false,
      existArr1: [],
      existArr2: [],
      existArr3: [],
        custDetail: {
            customerId: 0,
            firstName: '',
            lastName: '',
            companyName: '',
            phoneNumber: '',
            otherNumber: '',
            emailAddress: '',
            rating: 0,
            hotNotes: '',
        },
        errObj: {
            firstNameErr: '',
            lastNameErr: '',
            primNumErr: '',
            otherNumberErr: '',
            emailErr: ''
        }
    }
  },
  props: [],
   created() {
  },
  mounted () {
    document.body.style = 'overflow: hidden;'
  },
  watch: {},
  methods: {
    saveNewCustomer () {
      let isValid = true
      if (this.custDetail.firstName.trim() === '') {
        isValid = false
        this.errObj.firstNameErr = 'First name is required'
      }
      if (this.errObj.primNumErr !== '' || this.errObj.otherNumberErr !== "" || this.errObj.emailErr !== '' || this.numberExist1 || this.numberExist2 || this.emailExist) {
        isValid = false
      }
      if (isValid) {
         MyJobApp.saveNewCustomer(
         this.custDetail,
          response => {
            let data = null
            if (this.custDetail.companyName === '') {
              data = {
                  companyName: this.custDetail.firstName + ' ' + this.custDetail.lastName,
                  customerId: response.Data
              }
            } else {
              data = {
                  companyName: this.custDetail.companyName,
                  customerId: response.Data
              }
            }
            this.$root.$emit('addCustomerHandler', true, data)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('addCustomerHandler')
    },
    checkContactNumber (from) {
        if (from === 'prime') {
          let value = checkSpeialChar(this.custDetail.phoneNumber)
          console.log('value', value)
          if (value) {
            this.errObj.primNumErr = 'Please enter valid contact number'
          } else {
            this.errObj.primNumErr = ''
          }
            // if (this.custDetail.phoneNumber.length < 10 && this.custDetail.phoneNumber !== '') {
            //     this.errObj.primNumErr = 'Please enter valid contact number'
            // } else if (this.custDetail.phoneNumber.length === 10 && this.custDetail.phoneNumber.trim() !== '' || this.custDetail.phoneNumber.trim() === '') {
            //     this.errObj.primNumErr = ''
            // }
            if (this.custDetail.phoneNumber !== '' ) {
              this.checkExist('number', this.custDetail.phoneNumber, from)
          }
        }
        if (from === 'other') {
          let value = checkSpeialChar(this.custDetail.otherNumber)
          console.log('value', value)
          if (value) {
            this.errObj.otherNumberErr = 'Please enter valid contact number'
          } else {
            this.errObj.otherNumberErr = ''
          }
            // if (this.custDetail.otherNumber.length < 10 && this.custDetail.otherNumber !== '') {
            //     this.errObj.otherNumberErr = 'Please enter valid contact number'
            // } else if (this.custDetail.otherNumber.length === 10 && this.custDetail.otherNumber.trim() !== '' || this.custDetail.otherNumber.trim() === '') {
            //     this.errObj.otherNumberErr = ''
            // }
            if (this.custDetail.otherNumber !== '' ) {
              this.checkExist('number', this.custDetail.otherNumber, from)
          }
        }
      
    },
    checkExist (from, value, contact) {
      MyJobApp.getLeadCustEmailNumCheck(
        from,
        value,
        0,
        response => {
          if (from === 'number' && response.Data && contact === 'prime') {
            this.numberExist1 = true
            this.existArr1 = response.Data
          } else if (from === 'number' && response.Data && contact === 'other') {
              this.numberExist2 = true
              this.existArr2 = response.Data
          } else if (from === 'email' && response.Data && contact === 'email') {
              this.emailExist = true
              this.existArr3 = response.Data
          }
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      ) 
    },
    routingTodata (value) {
      let url = ''
      if (value.isLead) {
        url = this.$router.resolve({name: 'LeadDetail', params: {leadId: value.customerId}})
      } else {
        url = this.$router.resolve({name: 'customerDetails', params: {customerId: value.customerId}})
      }
      window.open(url.href, '_blank')
    },
    checkEmail () {
      if (this.custDetail.emailAddress !== '' && validateEmail(this.custDetail.emailAddress) === false) {
          this.errObj.emailErr = "Please enter valid email";
          return;
      } else {
        this.errObj.emailErr = "";
        if (this.custDetail.emailAddress !== '') {
          this.checkExist('email', this.custDetail.emailAddress, 'email')
        }
        // this.verifycustomerDataExist('customer_email', this.custDetail.emailAddress)
      }
    },
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>

</style>