<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Create Task</h1>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
      <div class="sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class=" card rounded-xl bg-white p-4 my-4 ">
          <div class="mb-4 ">
            <TextField
              :inputId="'jobtitlename'"
              :textMaxlength="100"
              :showcharLimit="true"
              :inputext="taskObject.taskTitle"
              :fieldError="errObj.titleErr !== ''"
              :placholderText="`Title`"
              :lableText="'Title'"
              :autoFocus="false"
              @keyPressAction="errObj.titleErr = ''"
              @inputChangeAction="(data) => taskObject.taskTitle = data"  />

              <p class="text-error heading-7">{{errObj.titleErr}}</p>
          </div>
          <div class=" col-span-2 mb-4">
            <div class="heading-5" v-if="!addJobDesc">
              <span @click="addJobDesc = true" class="text-primary font-bold cursor-pointer">+ Add Description</span>
            </div>
            <TextAreaInput v-if="addJobDesc"
              :inputId="'discriptioncon'"
              :inputext="taskObject.taskDescription"
              :placholderText="`Write description here...`"
              :lableText="'Description'"
              :inputType="'text'"
              :autoFocus="false"
              :textMaxlength="1000"
              :showcharLimit="true"
              :cols="50"
              :rows="3"
              :setReadOnly="false"
              @inputChangeAction="(data) => taskObject.taskDescription = data"  />
          </div>
          <div class=" grid grid-cols-2 gap-4 mb-4">
            <div class="mb-4">
              <Dropdown
              :disabled="isDisable"
              :inputext="searchForCustomer" 
              :inputId="'searchwp-Customer'"
              :lableText="'Client'"
              :placholderText="`Search Client`"
              :autoFocus="false"
              :showArrow="true"
              :setReadOnly="false"
              :searchCallApi="true"
              :fieldError="errObj.custErr !== ''"
              :showPlus="true"
              :keyName="'companyName'"
              :listId="'customerlistid'"
              :items="customersList"
              @enterkeyPressAction="errObj.custErr =''"
              @inputChangeAction="callsearchCust"
              @addNew="addNewCustomer"
              @selectNewForVal="selectNewCustomer"
              />
              <p v-if="errObj.custErr !== ''" class="text-error heading-7">{{errObj.custErr}}</p>
          </div>
            <div v-if="showAddSelectAddr && !isOfficeAddressSel" class="mb-4 sm:mb-0 col-span-2">
              <div class="mb-4 sm:mb-0 col-span-2">
                <p class="text-text1 font-semibold">Select Location</p>
                  <customer-address-selection :lat="0" :long="0" @setSelectedAddress="setSelectedAddress" :customerId="taskObject.customerId" />
              </div>
              <p v-if="errObj.addressErr !== ''" class="text-error heading-7">{{errObj.addressErr}}</p>
            </div>
          </div>
          <!-- <div v-if="showAddSelectAddr" class="flex items-center ">
            <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck()" v-model="isOfficeAddressSel" >
            <span class="pl-2 text-text2 cursor-pointer" @click="checkboxCheck()">Use Office Location</span>
          </div> -->
       
        <div class="">
          <SheduleVisit :isFromSchedule="false" :title="'Schedule'" :showEmployeeSelection="true" :showJobItemSelection="true" :visitErr="visitErr" />
        </div>
        <div class="flex justify-end">
          <div class="text-rigth flex gap-2">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="crateJob()"/>
          </div>
        </div>
        </div>
      </div>
    </div>
    
    <div>
    </div>
    <AddCustomer v-if="addCustomer" />
    <AddressAdd :showAdditional="true" v-if="addNewjobAdd" />
   </div>
</template>
<script>
import moment from 'moment'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import SheduleVisit from './addTaskScheduleComp.vue'
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import AddCustomer from '@/View/customer/components/AddCustomer.vue'
import deboucneMixin from '@/mixins/debounce.js'
import AddressAdd from '@/View/components/AddressAdd.vue'
import CustomerAddressSelection from '../../../request/components/customerAddressSelection.vue';
export default {
  name: "customer",
  components: {
    Button,
    TextAreaInput,
    AddCustomer,
    AddressAdd,
    TextField,
    SheduleVisit,
    Dropdown,
    CustomerAddressSelection,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      addJobDesc: false,
      isOfficeAddressSel: false,
      temporaryData: [],
      islead: false,
      isDisable: false,
      jobDisplayAdd: '',
      customersList: [],
      addCustomer: false,
      visitErr: false,
      showAddSelectAddr: false,
      addNewjobAdd: false,
      addressList: [],
      searchForCustomer: '',
      searchApitext: '',
      errObj: {
        custErr:'',
        titleErr:'',
        addressErr:''
      },
      getCustList: () => {},
      taskObject: {
        showEndErr: false,
        isNotReqEndDate: false,
        isTimeErr: false,
        selectFrequntlyVal: '',
        selectedRepeatedValue: '',
        isRepeated: false,
        repeatEndsOn: '',
        repeatEvery: 0,
        taskRepeatRule: {
          repeatType: '',
          repeatRule: ''
        },
        reminderBeforeMinutes: 0,
        taskDate: '',
        startTime: '',
        endTime: '',
        customerAddressId: 0,
        customerId: 0,
        taskTitle: '',
        taskDescription: '',
        fullAddress: '',
        assignToList: [],
        selectedWeekData: [],
        selectedMonthData: [],
        selectedRadioValue: 0,
        taskAddressObj: null
      }
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
    taskObject: {
      handler () {
        console.log('taskObject------------------->>>>>>>>----------------->>>>>', this.taskObject)
        if (!this.taskObject.isRepeated) {
          this.taskObject.repeatEvery = 0
        }
      },
      deep: true
    }
  },
  mounted() {
    this.taskObject.taskDate = moment(new Date()).format('YYYY-MM-DD')
    if (this.$route.query.customerId > 0) {
      this.isDisable = true
      this.getCustLeadInfo(parseInt(this.$route.query.customerId))
    }
    console.log("route", this.$route.query);
     this.getCustList = this.debounce(function () {
      this.getCustomerList()
    }, 500)
      this.getCustomerList()
    this.$root.$on("addCustomerHandler", (response, data) => {
      if (response && this.addCustomer) {
        this.selectNewCustomer(data)
      }
      this.addCustomer = false
    });
    this.$root.$on('addAddressHandler', (response, data) => {
      if (response && data !== null && this.addNewjobAdd) {
        this.setAddress(data)
      }
        this.addNewjobAdd = false
    })
    this.$root.$on('setReminderVal', (response) => {
      console.log('response', response)
      this.taskObject.reminderBeforeMinutes = response.value
    })
    this.$root.$on('temporaryData', (data) => {
      console.log('response', data)
      this.temporaryData = data
    })
    this.$root.$on('repeatSchData', (response, value) => {
      console.log('response 112', value)
      this.taskObject.isRepeated = response.isRepeat
      this.taskObject.selectedRepeatedValue = value
    })
    this.$root.$on('repeatScheduleValue', (response) => {
      console.log('response', response)
      this.taskObject.isRepeated = response.isRepeat
      this.taskObject.repeatEvery = response.dateValue
      this.taskObject.selectFrequntlyVal = response.selectFreqVal
      this.taskObject.selectedWeekData = response.selectedWeekData
      this.taskObject.selectedMonthData = response.selectedMonthData
      this.taskObject.selectedRadioValue = response.radioModel
    })
    this.$root.$on('setSelectedEmpHandler', (response) => {
      this.taskObject.assignToList = response
    })
    this.$root.$on('scheduleTimeEmit', (response) => {
      console.log('response 11', response)
      this.taskObject.startTime = response.compStartTime
      this.taskObject.endTime = response.compEndTime
      this.taskObject.repeatEndsOn = response.repeatEndsOn
      this.taskObject.taskDate = moment(new Date(response.compStartTime)).format('YYYY-MM-DD')
      this.taskObject.isTimeErr = response.Error
      this.taskObject.isNotReqEndDate = response.isEndDateNeed
      this.taskObject.showEndErr = response.showEndErr
    })
  },
  beforeDestroy() {
    this.$root.$off("addCustomerHandler");
    this.$root.$off("setItemSelectedHandler");
    this.$root.$off("addAddressHandler");
    this.$root.$off("finalvisitlistHandler");
    this.$root.$off("setReminderVal");
    this.$root.$off("repeatSchData");
    this.$root.$off("repeatScheduleValue");
    this.$root.$off("setSelectedEmpHandler");
    this.$root.$off("setSheduleDataEmpHandler");
    this.$root.$off("scheduleTimeEmit");
  },
  methods: {
    setSelectedAddress(addresObj) {
        if (addresObj !== null) {
            this.taskObject.taskAddressObj = {
                addressLine1: addresObj.addressLine1 ? addresObj.addressLine1 : '',
                addressLine2: addresObj.addressLine2 ? addresObj.addressLine2 : '',
                city: addresObj.city ? addresObj.city : '',
                state: addresObj.state ? addresObj.state : '',
                zip: addresObj.zip ? addresObj.zip : '',
                country: addresObj.country ? addresObj.country : '',
                latitude: addresObj.latitude ? addresObj.latitude : 0,
                longitude: addresObj.longitude ? addresObj.longitude : 0,
                geofenceRangeDiameter: addresObj.geofenceRangeDiameter ? addresObj.geofenceRangeDiameter : 0,
                fullAddress: addresObj.fullAddress ? addresObj.fullAddress : '',
                houseNo: addresObj.houseNo ? addresObj.houseNo : '',
                floorNumber: addresObj.floorNumber ? addresObj.floorNumber : '',
                buildingName: addresObj.buildingName ? addresObj.buildingName : '',
                directionNote: addresObj.directionNote ? addresObj.directionNote : '',
                contactNumber: addresObj.contactNumber ? addresObj.contactNumber : '',
                contactName: addresObj.contactName ? addresObj.contactName : '',
                addressTitle: addresObj.addressTitle ? addresObj.addressTitle : '',
                customerId: addresObj.customerId ? addresObj.customerId : 0,
                isAddressAddToCustomer: addresObj.isAddressAddToCustomer ? addresObj.isAddressAddToCustomer : false,
            }
        } else {
            this.taskObject.taskAddressObj = null
        }
    },
    crateJob () {
      console.log('this.taskObject---> SAVE---> ', this.taskObject)
      if (this.taskObject.selectedRepeatedValue === 'everyday') {
        this.taskObject.repeatEvery = 1
        this.taskObject.taskRepeatRule.repeatType = 'd'
      } else if (this.taskObject.selectedRepeatedValue === 'week') {
        this.taskObject.repeatEvery = 1
        this.taskObject.taskRepeatRule.repeatType = 'w'
        this.taskObject.taskRepeatRule.repeatRule = JSON.stringify([
          new Date(this.taskObject.taskDate).getDay(),
        ])
      } else if (this.taskObject.selectedRepeatedValue === 'month') {
        this.taskObject.repeatEvery = 1
        this.taskObject.taskRepeatRule.repeatType = 'm'
        this.taskObject.taskRepeatRule.repeatRule = JSON.stringify([
          new Date(this.taskObject.taskDate).getDate(),
        ]);
      } else if (this.taskObject.selectedRepeatedValue === 'custom') {
        this.taskObject.repeatEvery = parseInt(this.taskObject.repeatEvery)
        if (this.taskObject.selectFrequntlyVal === 'Daily') {
          this.taskObject.taskRepeatRule.repeatType = 'd'
        } else if (this.taskObject.selectFrequntlyVal === 'Weekly') {
          this.taskObject.taskRepeatRule.repeatType = 'w'
          const wDays = [];
          this.taskObject.selectedWeekData.forEach(element => {
            wDays.push(element.id);
          });
          this.taskObject.taskRepeatRule.repeatRule = JSON.stringify(wDays)
        } else if (this.taskObject.selectFrequntlyVal === 'Monthly' && this.taskObject.selectedRadioValue === 0) {
          this.taskObject.taskRepeatRule.repeatType = 'm'
          const wDays = [];
          this.taskObject.selectedMonthData.forEach(element => {
            console.log('elementelementelement', element)
            wDays.push(element.id);
          });
          this.taskObject.taskRepeatRule.repeatRule = JSON.stringify(wDays)
        } else if (this.taskObject.selectFrequntlyVal === 'Monthly' && this.taskObject.selectedRadioValue === 1) {
          this.taskObject.taskRepeatRule.repeatType = 'wd'
          const wdList = {
            '0': [],
            '1': [],
            '2': [],
            '3': [],
            '4': [],
            '5': [],
            '6': [],
          }
          console.log('temporaryData', this.temporaryData)
          for (let index = 0;index < this.temporaryData.length;index++) {
            const element = this.temporaryData[index];
            console.log('element', element)
            const dList = element.obj.filter(x => x.isSel);
            console.log('dList', dList)
            dList.forEach(dVal => {
              console.log('dVal', dVal)
              wdList[dVal.id].push(index + 1);
            });
          }
          this.taskObject.taskRepeatRule.repeatRule = JSON.stringify(wdList);
        }
      }
      console.log('this.taskObject INSIDE SAVE', this.taskObject)
      let isValid = true
      if (this.taskObject.taskTitle.trim() === '') {
        this.errObj.titleErr = 'title is required'
        isValid = false
      }
      if (this.taskObject.isTimeErr) {
        isValid = false
      }
      if (this.taskObject.assignToList.length === 0) {
        this.$root.$emit('assigneeErr', true)
        isValid = false
      }
      console.log('this.taskObject.isNotReqEndDate', this.taskObject.isNotReqEndDate)
      console.log('this.taskObject.showEndErr', this.taskObject.showEndErr)
      if (this.taskObject.showEndErr) {
        isValid = false
      }
      if (this.taskObject.isNotReqEndDate) {
        this.taskObject.repeatEndsOn = ''
      }
      if (isValid) {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.SaveTaskDetail(
          this.taskObject,
          (response) => {
              this.$router.push({name: 'TaskDetail', params: {taskId: response.Data}, query: {date: response.taskStartFrom}})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
      }
      
    },
    setAddress (data) {
      this.jobDisplayAdd = data !== null ? data.fullAddress : ''
      this.taskObject.customerAddressId = data !== null ? data.customerAddressId : 0
    },
    selectNewCustomer (data) {
        if (data !== null) {
          this.islead = data.isLead  
          this.jobDisplayAdd = ''
          this.taskObject.customerAddressId = 0
          this.errObj.custErr = ''
          this.searchForCustomer = data.companyName
          this.taskObject.customerId = data.customerId
          this.showAddSelectAddr = true
          // this.getCustDetails(data.customerId)
        } else {
            this.searchForCustomer = ''
            this.taskObject.customerId = 0
        }
    },
    addNewCustomer () {
        this.addCustomer = true
    },
    callsearchCust (text) {
      this.searchApitext = text
     this.getCustList()
    },
    getCustomerList () {
      this.customersList = []
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        '',
        response => {
            let tempList = response.Data.tableRow !== null ? response.Data.tableRow : []
           tempList.forEach(e => {
            let lead = e.isLead ? '(lead)' : '(customer)'
            e.companyName = e.companyName + ' ' + lead
            this.customersList.push(e)
           });
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
    getCustLeadInfo(custId) {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.searchForCustomer = response.Data.companyName
          this.taskObject.customerId = response.Data.customerId
          this.showAddSelectAddr = true
          // this.getCustDetails(response.Data.customerId)
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getCustDetails(custId) {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.addressList = response.Data.addressList !== null ? response.Data.addressList : []
          if (this.addressList.length === 1) {
            this.jobDisplayAdd = this.addressList[0].fullAddress
            this.taskObject.customerAddressId = this.addressList[0].customerAddressId
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    checkboxCheck () {
      this.isOfficeAddressSel = !this.isOfficeAddressSel
      this.taskObject.customerAddressId = -1
      this.jobDisplayAdd = ''
      if (!this.isOfficeAddressSel) {
        this.getCustDetails(this.taskObject.customerId)
      }
    },
  },
};
</script>
<style scoped>

</style>